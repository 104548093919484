.instagram {
	position: relative;
	.owl-nav {
		margin: 0;
	}	
	.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		background-color: rgba(0,0,0, 0.2);
		height: 70px;
		width: 40px;
		margin: 0;
		font-size: 28px;
		&:focus {
			outline: 0;
		}
		&:hover {
			transition: all 0.3s linear;
			color: #e4405f;
			background-color: rgba(0,0,0, 0.6);
		}
	}
	.owl-carousel .owl-nav button.owl-prev {
		left: 0;
		border-radius: 0 6px 6px 0;	
	}

	.owl-carousel .owl-nav button.owl-next {
		right: 0;
		border-radius: 6px 0 0 6px;
	}
	.follow {
		position: absolute;
		color: white;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 5;
		a {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: #ffffff;
			padding: 134px 42px;
			i {
				width: 100%;
				margin-bottom: 55px;
				padding: 0 2px 0 3px;
				font-size: 55px;
				color: #b9a271;
			}
			.title {
				font-size: 36px;
				margin-bottom: 29px;
				font-style: italic;
			}
			.description {
				font-size: 36px;
				margin-bottom: 0;
				color: #bfbfbf;
				font-weight: lighter;
			}
		}
		&.version_2 {
			a {
				transform: rotate(45deg);
				padding: 20px;
				.title, .description {
					display: none;
				}
				i {
					margin: 0;
					transform: rotate(-45deg);
					font-size: 40px;
				}
			}
		}
	}
	.item {
		width: 100%;
		.image {
			width: auto;
			height: 375px;
			background-size: cover;
		}
		&:hover .overlay {
			opacity: 1;
		}
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: .5s ease;
			color: white;
			font-size: 50px;
			a {
				position: absolute;
				width: 100%;
				height: 100%;
				i {
					color: white;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					&:hover {
						transition: all 0.2s linear;
						color: rgba(228,64,95 ,1 );
					}
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.instagram .follow {
		display: none;
	}
}