.ova_heading{
	h1.heading_title{
		font-size: 50px;
		color: #25282e;
		margin-bottom: 24px;
		margin-top: 0;
		font-weight: normal;
		line-height: 1.2em;
	}
	h2.sub_title{
		font-size: 22px;
		color: #888;
		line-height: 32px;
		margin: 0;
	}
}