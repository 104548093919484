.wrap_ova_header{
	width: 100%;
	position: relative;
	
	&.bg_feature_img{
		background-size: cover!important;
		background-repeat: no-repeat!important;
		background-position: center center!important;
	}
	.cover_color{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.ova_header_el{
		position: relative;
		z-index: 1;
	}

	/*Breadcrumbs*/
	.ovatheme_breadcrumbs_el{
		background-color: transparent;
		margin: 0;
		padding: 0;
		ul.breadcrumb{
			background-color: transparent;
		}
	}

	/*title*/
	h1.header_title{
		margin: 0;
		padding: 0;
	}
}