.ova-image-muzze {
	position: relative;
	.image {
		a {
			display: block;
			overflow: hidden;
			img {
				transition: 0.5s;
			}
		}
	}
	.text-button {
		position: absolute;
	    width: 100%;
	    left: 0;
	    top: 100%;
		a {
			font-size: 20px;
			line-height: 26px;
			color: #fff;
			background-color: #25282e;
			padding: 17px 10px 17px 10px;
			display: block;
			text-align: center;
			transition: 0.2s;
			
			
		}
	}
	&:hover {
		.image {
			img {
				transform: scale(1.1);
			}
		}
		.text-button {
			a {
				padding: 22px 10px 22px 10px;
				transform: translateY(-10px);
				transform-origin:bottom;
				background-color: #ff8b2f;
				
			}
		}
	}
	&.version_2 {
		.image {
			position: relative;
			.desc {
				position: absolute;
				left: 29px;
				bottom: 33px;
				display: inline-block;
				line-height: 35px;
				color: #fff;
				font-size: 32px;
			}
			&:before {
				position: absolute;
				content: "";
				top: 0;
				right: 0;
				bottom: 0;
				left: 0px;
				background-image: linear-gradient(180deg, transparent, #000);
			    opacity: .6;
			}
		}
		
	}
}