.admission-price {
	.item-price {
		padding: 9px 0 10px 0;
		border-bottom: 1px solid #e8e8e8;
		.con-item {
			width: 50%;
			display: flex;
			justify-content: space-between;
			span {
				font-size: 17px;
				line-height: 30px;
				color: #555555;
				&:last-child {
					padding-left: 10px;
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.admission-price {
		.item-price {
			.con-item {
				width: 100%;
			}
		}
	}
}